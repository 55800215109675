import { ReactElement, useState } from 'react';
import { ProfileNote, Leader, ProfileCategory } from '../../store';
import { getProfileNoteTemplate } from '../../store/templates';
import NoteOnboarding from '../../components/NoteOnboarding';
import { NoteType, EVENT_TYPE, EXEC_CARD_TYPES } from '../../constants';
import CardsPageHeader from '../../components/Common/CardsPageHeader';
import InfoCardContainer from '../../components/InfoCard/InfoCardContainer';
import { trackEventWithExtra } from '../../utils/appAnalyticsUtils';
import { InfoCardFields } from '../../utils/types';
import EditProfileCategory from '../../components/ProfileNotes/EditProfileCategory';
import { NO_NOTES_GRAPHIC_SRC } from '../../resourceUrls';
import CabinetPage from '../../components/Common/CabinetPage';
import { CabButton, CabIcon } from '@CabComponents';
import { CABINET_ICONS, EMOJIS } from '../../icons';
import { CabModal } from '@CabComponents/CabModal';
import { Box, Grid, Typography } from '@mui/material';
import { IoArrowBack } from 'react-icons/io5';
import CabSpinner from '@CabComponents/CabSpinner';


const profileCopy = {
  title: 'Let\'s create your first custom note card',
  icon: EMOJIS.UNICORN,
  icon_url: CABINET_ICONS.FOLDER.icon_url,
  listCaption: 'Note cards are an easy way to track any information you want. For example:',
  info: [
    'Favorite restaurants for meetings',
    'Communication preferences',
    'Meeting preferences'
  ]
};

const infoFields: InfoCardFields<ProfileNote>[] = [
  {label: 'General Notes', key: 'notes', }
];

interface Props {
  category?: ProfileCategory;
  notes: ProfileNote[];
  leader: Leader;
  showNotes: boolean;
  loaded: boolean;
  hasEditPermission: boolean;
  pageTitle: string;
  showOnboarding: boolean;
  onSaveNote: (note: ProfileNote, addMode?: boolean) => Promise<void>;
  onDeleteProfileNote: (id: number) => Promise<void>;
  onBack: () => void;
  onOnboardingClose: () => void;
}

export const ProfileCategoryPage = ({ 
  category, leader, notes, showNotes, loaded, hasEditPermission, pageTitle, showOnboarding,
  onSaveNote, onDeleteProfileNote, onBack, onOnboardingClose
}: Props): ReactElement => {
  const [newNote, setNewNote] = useState<ProfileNote>({
    ...getProfileNoteTemplate(),
    ...(category?.id ? { profile_category: category?.id } : {} )
  });
  const [addMode, setAddMode] = useState(false);
  const [idToDelete, setIdToDelete] = useState(-1);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [editCategoryOpen, setEditCategoryOpen] = useState(false);

  const handleSaveNote = async (note: ProfileNote) => {
    await onSaveNote(note, addMode);
    setAddMode(false);
  };

  const handleOpenDeleteAlert = (profileNoteId: number): (() => void) => 
    (): void => {
      setIdToDelete(profileNoteId);
      setDeleteAlertOpen(true);
    };

  const handleConfirmDelete = (): void => {
    setDeleteAlertOpen(false);
    onDeleteProfileNote(idToDelete);
    trackEventWithExtra({ eventName: EVENT_TYPE.DELETE_EXEC_CATEGORY_CARD, extra: {category: EXEC_CARD_TYPES.CUSTOM} });
  };

  const handleAddNewNote = (): void => {
    setNewNote({...newNote});
    setAddMode(true);
  };

  const handleCancelAdd = (): void => {
    setAddMode(false);
  };

  const handleEditCategory = (): void => {
    setEditCategoryOpen(true);
  };


  const renderProfileCards = (): ReactElement => (
    <Grid container spacing={2}>
      <Grid item hidden={true}>
        <InfoCardContainer note={newNote} saveNote={handleSaveNote} addMode={addMode} 
          title="Card" currentLeader={leader}
          openDeleteAlert={handleOpenDeleteAlert(newNote.id)} noteType={NoteType.PROFILE} 
          infoFields={infoFields} cancelAdd={handleCancelAdd}/>
      </Grid>
      {showNotes && notes.map((note): ReactElement => 
        <Grid item xs={12} md={6} key={note.id}>
          <Box height='100%'>
            <InfoCardContainer note={note} saveNote={handleSaveNote} addMode={false} 
              title="Card" currentLeader={leader}
              openDeleteAlert={handleOpenDeleteAlert(note.id)} noteType={NoteType.PROFILE} 
              infoFields={infoFields}/>
          </Box>
        </Grid>
      )}
    </Grid>
  );
  
  const renderPlaceholder = (): ReactElement | null => {
    if (loaded && notes.length === 0) {
      return ( 
        <Box display='flex' width='100%' flexDirection='column' height="100%" alignItems="center" 
          justifyContent="center" gap={1}>
          <Box component={'img'} src={NO_NOTES_GRAPHIC_SRC} alt="No notes yet" />
          <Typography variant='h5'>There are no cards in this category yet</Typography>
          {hasEditPermission &&
           <CabButton onClick={handleAddNewNote}>
             Create your first card
           </CabButton>
          }
        </Box>
      );
    } else {
      return null;
    }
  };

  const renderSpinnerOrContent = (): ReactElement => {
    if (!loaded) {
      return <Box display='flex' height="100%" alignItems="center" justifyContent="center">
        <CabSpinner scale={4}/>
      </Box>;
    } else {
      return (
        <>
          <Box width='100%' display='flex' flexDirection='column' padding={2} gap={2}>
            {leader && (
              <Box width='100%' display='flex'>
                <CabButton
                  onClick={onBack}
                  icon={<CabIcon Icon={IoArrowBack} />}
                  buttonType="tertiary"
                  color="accent"
                  size="small"
                >
                  Back
                </CabButton>
              </Box>
            )}
            <Box>
              {renderPlaceholder()}
              {renderProfileCards()}
            </Box>
          </Box>
          <CabModal
            open={deleteAlertOpen}
            onClose={() => setDeleteAlertOpen(false)}
            isAlert
            closeIcon
            title='Warning'
            text='Are you sure you want to delete this card?'
            noFullScreen
            actionButtons={
              <>
                <CabButton buttonType='secondary' onClick={() => setDeleteAlertOpen(false)}>
                  Cancel
                </CabButton>
                <CabButton onClick={handleConfirmDelete}>
                  Yes, delete it
                </CabButton>
              </>
            }
          />
        </>
      );
    }
  };

  return (
    <CabinetPage
      pageName={'ExecCustomCategory'}
      headerContent={
        <>
          {leader && 
          <CardsPageHeader 
            title={pageTitle} 
            addCard={hasEditPermission ? handleAddNewNote : undefined}
            editCategory={!leader.is_shared ? handleEditCategory : undefined}
          />
          }
        </>
      }
    >
      {renderSpinnerOrContent()}
      <CabModal
        closeOnBackdropClick
        open={showOnboarding}
        onClose={onOnboardingClose}
        title={(
          <Box display="flex" alignItems="center" gap={1} paddingTop={2}>
            <Box component={'img'} src={profileCopy.icon_url} alt={'note'} height={40} />
            {profileCopy.title}
          </Box>
        )}
        actionButtons={
          <CabButton size="large" buttonType="primary" onClick={onOnboardingClose}>
            Got it!
          </CabButton>
        }
        sx={{ ".MuiPaper-root": { minWidth: '500px' } }}
      >
        <NoteOnboarding copy={profileCopy} />
      </CabModal>


      {leader && 
        <EditProfileCategory
          leader={leader}
          isOpen={editCategoryOpen}
          category={category}
          onDidDismiss={(): void => setEditCategoryOpen(false)}
        />
      }
    </CabinetPage>
  );
};

export default ProfileCategoryPage;